import React from "react";
//import MainMenuController from "../js/MainMenuController";
import "@popperjs/core";

import "../../styles/main.scss";

import logo from "../../images/mamaai_logo--white.svg";

import Metadata from "../../js/Metadata";
import Navbar from "../../js/Navbar";
import Footer from "../../js/Footer";
import QuotesCarousel from "../../js/QuotesCarousel";
import AiAcademyToast from "../../js/layout/ai_academy_toast";

const AiAcademyPage = () => {
    const description =
        "Would you like to learn something practical about artificial intelligence directly from the source? Visit our unique AI Academy!";
    const quotes_cz = [
        {
            heading:
                "Oba dny byly skvělé. Obdivovala jsem, jak vše dokážete srozumitelně, zajímavě a s vtipem vysvětlit. Ve vašem podání se AI - pro laika, jako jsem já - stává fakt zajímavým tématem a mám v plánu projít další kurzy. Díky moc!",
            content: "Education Project Manager",
        },
        {
            heading:
                "Zábavná cvičení, která ukazují velký potenciál pro využití napříč obory.",
            content: "Marketing specialist",
        },
        {
            heading:
                "Líbilo se mi, že vše bylo srozumitelné i pro člověka bez hlubšího matematického vzdělání, informatiky nebo AI.",
            content: "Executive Director in Marketing",
        },
        {
            heading:
                "Dozvěděla jsem se spoustu nových informací, které byly proložené praktickými ukázkami. Vše bylo velmi srozumitelně vysvětleno a časově výborně pojaté (ani moc ani málo, akorát). Díky!",
            content: "Education Project manager",
        },
        {
            heading:
                "Jupyter notebooky byly podle mě skvěle připraveny i pro lidi bez hlubší znalosti o programování.",
            content: "Data Scientist",
        },
        {
            heading:
                "Akademie byla interaktivní, zajímavá, vhodná i pro lidi, kteří nejsou extrémně technicky zdatní.",
            content: "Data Analytics Consultant",
        },
        {
            heading:
                "Profesionální přístup všech prezentujích, kteří byli schopni srozumitelně vysvětlit danou problematiku i naprostému laikovi",
            content: "Education - lector of languages",
        },
        {
            heading:
                "Ohledně teoretické části: musí být, ale je těžké podat ji zábavně a jednoduše - Jan K. to první den zvládl úplně skvěle!",
            content: "Sales leader",
        },
    ];
    const quotes_en = [
        {
            heading:
                "Both days were great. I admired how you can explain everything in a clear, interesting and witty way. In your presentation, AI - for a layman like me - becomes a really interesting topic and I plan to go through more courses. Thanks a lot!",
            content: "Education Project Manager",
        },
        {
            heading:
                "Fun exercises that show great potential for use across disciplines.",
            content: "Marketing specialist",
        },
        {
            heading:
                "I liked that everything was understandable even for a person without a deep mathematical, computer science or AI background.",
            content: "Executive Director in Marketing",
        },
        {
            heading:
                "I learned a lot of new information, which was interspersed with practical demonstrations. Everything was very clearly explained and the timing was excellent (neither too much nor too little, just enough). Thank you!",
            content: "Education Project manager",
        },
        {
            heading:
                "Jupyter notebooks were, in my opinion, great even for people without a deep knowledge of programming.",
            content: "Data Scientist",
        },
        {
            heading:
                "The academy was interactive, interesting, suitable even for people who are not extremely tech-savvy.",
            content: "Data Analytics Consultant",
        },
        {
            heading:
                "Professional approach of all the presenters, who were able to explain the subject in an understandable way even to a complete layman",
            content: "Education - lector of languages",
        },
        {
            heading:
                "About the theoretical part: it has to be, but it is difficult to present it in a fun and simple way - Jan K. did it perfectly on the first day!",
            content: "Sales leader",
        },
    ];
    return (
        <div className="container-max-width">
            <Metadata
                title="THE MAMA AI ACADEMY"
                url="https://themama.ai/products/aiacademy"
                description={description}
            />
            <Navbar />
            <section className="ai-logo-section section g-0 pb-5">
                <div>
                    <div id="home" className="section__anchor" />
                    <header className="text-center logo-header m-auto">
                        <a href="/#home">
                            <img
                                className="logo-header__logo d-block m-auto"
                                src={logo}
                                alt="The mama AI"
                            />
                        </a>
                        <h1 className="fs-3 pt-5 text--color-2 text-uppercase">
                            Academy
                        </h1>
                        <a
                            href="#english-version"
                            className="english-btn btn-primary text-white btn m-auto mt-3 text-decoration-none"
                            role="button"
                        >
                            English version
                        </a>
                    </header>
                </div>
            </section>
            <section className="section ai-details-section pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9 col-xl-8 m-auto">
                            <p>
                                Chtěli byste se o umělé inteligenci něco
                                praktického dozvědět přímo od pramene? Sáhnout
                                si na AI a něco rovnou postavit? Ušpinit si ruce
                                a přitom zažít pocit, že jste vytvořili něco
                                fakt užitečného?
                            </p>
                            <p className="pt-2 text-center">
                                Navštivte naši unikátní{" "}
                                <span className="text text--color-2">
                                    AI Academy
                                </span>
                                !
                                <br />
                                Přihlášky:{" "}
                                <a
                                    href="mailto:academy@themama.ai"
                                    className="text text--color-2"
                                >
                                    academy@themama.ai
                                </a>
                            </p>
                            <p className="pt-2">
                                Termín konání:{" "}
                                <span className="text text--color-2">
                                    21. - 22. ledna 2025, 14:00 - 18:00
                                </span>
                                <br />
                                Místo konání:{" "}
                                <span className="text text--color-2">
                                    Palác YMCA,
                                </span>{" "}
                                Na Poříčí 1041, Praha 1,{" "}
                                <span className="text text--color-2">
                                    konferenční místnost 549
                                </span>
                                <br />
                                Cena kurzu:{" "}
                                <span className="text text--color-2">
                                    6,400- Kč (cena je bez DPH, sleva 20% pro
                                    přihlášky do 10. 1. 2025)
                                </span>
                                <br />
                                Pokud si přinesete vlastní počítač či tablet,
                                budete moci naplno využít praktické části
                                workshopu.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <QuotesCarousel
                data={quotes_cz}
                lightMode={true}
                title="Co řekli účastníci o MAMA AI Academy:"
            />
            <section className="section ai-details-section pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9 col-xl-8 m-auto">
                            <h2 className="pt-4">
                                <strong>
                                    Den 1: Úvod do umělé inteligence a praktické
                                    AI pro zpracování hlasu
                                </strong>
                            </h2>
                            <p className="pt-3">
                                14:00 - 15:30 Část{" "}
                                <strong>
                                    1.1 <u>Úvod do AI pro byznys</u>
                                </strong>
                            </p>
                            <ul className="pb-2">
                                <li>Přehled typických případových studií</li>
                                <li>Co je potřeba vědět o AI?</li>
                                <li>
                                    Automatizace interakcí (voicebots, chatbots,
                                    omnichannel)
                                </li>
                                <li>
                                    Automatizace zpracování dokumentů
                                    (vytěžování informací z dokumentů, směrování
                                    e-mailů, toxicita)
                                </li>
                                <li>
                                    Automatizace předpovědí, optimalizace
                                    (předpovědní modely, doporučení, detekce
                                    anomálií)
                                </li>
                                <li>
                                    Jak měřit přínosy AI? Jaká jsou typická
                                    KPIs?
                                </li>
                            </ul>
                            <p className="pt-3">15:30 - 15:45 Občerstvení</p>
                            <p className="pt-3">
                                15:45 - 18:00 Část{" "}
                                <strong>
                                    1.2{" "}
                                    <u>
                                        Pojďte si Byznys AI opravdu vyzkoušet!
                                    </u>{" "}
                                    Oblast: lidský hlas
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    Typické případové studie – detailnější
                                    pohled na doménu zpracování lidského hlasu
                                </li>
                                <li>
                                    Postavte si vlastního voicebota (Telma
                                    odchozí hovory)
                                    <ul>
                                        <li>
                                            Pod povrchem: převod hlasu na text
                                            (STT), převod textu na řeč (TTS),
                                            řízení dialogu
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Vytvořte si vlastní personu pomocí převodu
                                    textu na řeč (mVoice Studio)
                                    <ul>
                                        <li>
                                            Pod povrchem: normalizace, SSML,
                                            Persona, emoce
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2 className="pt-4">
                                <strong>
                                    Den 2: Hlouběji do Business AI a praktické
                                    AI pro zpracování dokumentů
                                </strong>
                            </h2>
                            <p className="pt-3">
                                14:00 - 15:30 Část{" "}
                                <strong>
                                    2.1 <u>Hlubší pohled na Byznys AI</u>
                                </strong>
                            </p>
                            <ul className="pb-2">
                                <li>
                                    Strojové a hluboké učení (Machine Learning a
                                    Deep Learning) pro Byznys AI
                                </li>
                                <li>Data a jejich klíčový význam</li>
                                <li>Analýza dat pro vstup i výstup</li>
                                <li>
                                    Výběr modelu z pohledu potřeby byznysových
                                    KPIs
                                </li>
                            </ul>
                            <p className="pt-3">15:30 - 15:45 Občerstvení</p>
                            <p className="pt-3">
                                15:45 - 18:00 Část{" "}
                                <strong>
                                    2.2{" "}
                                    <u>
                                        Pojďte si Byznys AI opravdu vyzkoušet!
                                    </u>{" "}
                                    Oblast: text
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    Typické případové studie pro byznys –
                                    detailnější pohled na doménu zpracování
                                    textu - Python notebooks
                                </li>
                                <li>
                                    Zpracování dokumentů v přirozeném jazyce
                                    (mNLP)
                                    <ul>
                                        <li>
                                            Pod povrchem: jmenné entity,
                                            intenty, podobnost dokumentů
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Zpracování dokumentů (mSearch)
                                    <ul>
                                        <li>
                                            Pod povrchem: vyhledávání na
                                            webových stránkách
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Velké jazykové modely, způsoby použití,
                                    propojení s dalšími technologiemi
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section ai-details-section pb-5">
                <div className="container">
                    <div className="row">
                        <div
                            className="col-12 col-lg-9 col-xl-8 english-translation m-auto"
                            id="english-version"
                        >
                            <hr />
                            <p>
                                Would you like to learn something practical
                                about artificial intelligence directly from the
                                source? Get your hands on AI and build something
                                right away? Get your hands dirty and feel like
                                you‘ve created something really useful?
                            </p>
                            <p className="pt-2 text-center">
                                Visit our unique{" "}
                                <span className="text text--color-2">
                                    AI Academy
                                </span>
                                !
                                <br />
                                Applications:{" "}
                                <a
                                    href="mailto:academy@themama.ai"
                                    className="text text--color-2"
                                >
                                    academy@themama.ai
                                </a>
                            </p>
                            <p className="pt-2">
                                Date:{" "}
                                <span className="text text--color-2">
                                    January 21 - 22, 2025, 14:00 - 18:00
                                </span>
                                <br />
                                Venue:{" "}
                                <span className="text text--color-2">
                                    Palace YMCA,
                                </span>{" "}
                                Na Poříčí 1041, Praha 1,{" "}
                                <span className="text text--color-2">
                                    conference room 549
                                </span>
                                <br />
                                Course price:{" "}
                                <span className="text text--color-2">
                                    6,400- CZK (price is without VAT, 20%
                                    discount for applications until January
                                    10th, 2025)
                                </span>
                                <br />
                                If you bring your own computer or tablet, you
                                will be able to take full advantage of the
                                practical part of the workshop.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <QuotesCarousel
                data={quotes_en}
                lightMode={true}
                title="What participants said about MAMA AI Academy:"
            />
            <section className="section ai-details-section pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-9 col-xl-8 english-translation m-auto">
                            <h2 className="pt-4">
                                <strong>
                                    Day 1: Introduction to artificial
                                    intelligence and practical AI for voice
                                    processing
                                </strong>
                            </h2>
                            <p className="pt-3">
                                14:00 - 15:30 Part{" "}
                                <strong>
                                    1.1 <u>Introduction to AI for Business</u>
                                </strong>
                            </p>
                            <ul className="pb-2">
                                <li>Overview of typical case studies</li>
                                <li>What do I need to know about AI?</li>
                                <li>
                                    Automation of interactions (voicebots,
                                    chatbots, omnichannel)
                                </li>
                                <li>
                                    Document processing automation (extracting
                                    information from documents, email routing,
                                    toxicity)
                                </li>
                                <li>
                                    Forecast automation, optimization (forecast
                                    models recommendations, anomaly detection)
                                </li>
                                <li>
                                    How to measure the benefits of AI? What are
                                    typical KPIs?
                                </li>
                            </ul>
                            <p className="pt-3">15:30 - 15:45 Refreshments</p>
                            <p className="pt-3">
                                15:45 - 18:00 Part{" "}
                                <strong>
                                    1.2 <u>Let‘s really try out Business AI!</u>{" "}
                                    Area: human voice
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    Typical case studies – a more detailed view
                                    of the human voice processing domain
                                </li>
                                <li>
                                    Build your own voicebot (TELMA outbound
                                    calls)
                                    <ul>
                                        <li>
                                            Beneath the surface: voice to text
                                            (STT), text to speech (TTS),
                                            dialogue control
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Create your own persona with text-to-speech
                                    (mVoice studio)
                                    <ul>
                                        <li>
                                            Beneath the surface: normalization,
                                            SSML, Persona, emotions
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <h2 className="pt-4">
                                <strong>
                                    Day 2: Digging Deeper into Business AI and
                                    Practical AI for Document Processing
                                </strong>
                            </h2>
                            <p className="pt-3">
                                14:00 - 15:30 Part{" "}
                                <strong>
                                    2.1 <u>A Deeper Look at Business AI</u>
                                </strong>
                            </p>
                            <ul className="pb-2">
                                <li>
                                    Machine Learning and Deep Learning for
                                    Business AI
                                </li>
                                <li>Data and its key importance</li>
                                <li>Data analysis for input and output</li>
                                <li>
                                    Model selection in terms of the need for
                                    business KPIs
                                </li>
                            </ul>
                            <p className="pt-3">15:30 - 15:45 Refreshments</p>
                            <p className="pt-3">
                                15:45 - 18:00 Part{" "}
                                <strong>
                                    2.2 <u>Let‘s really try out Business AI!</u>{" "}
                                    Area: text
                                </strong>
                            </p>
                            <ul>
                                <li>
                                    Typical Business Case Studies – A Closer
                                    Look at the Word Processing Domain – Python
                                    notebooks
                                </li>
                                <li>
                                    Natural Language Processing (mNLP)
                                    <ul>
                                        <li>
                                            Beneath the surface: name entities,
                                            intents, document similarity
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Document processing (mSearch)
                                    <ul>
                                        <li>
                                            Beneath the surface: searching the
                                            websites
                                        </li>
                                    </ul>
                                </li>

                                <li>
                                    Large language models, usage patterns,
                                    interfacing with other technologies
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <AiAcademyToast isCzechPage={true} />
            <Footer />
        </div>
    );
};

export default AiAcademyPage;
